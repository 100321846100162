import { slackReporter } from '@/api/slack'
import configCommon from '@/utils/configCommon'

export function slackHandler(text, channel, attachments, forceSlack = false) {
  if (!forceSlack)
    if (!configCommon.isProduction) return
  slackReporter({
    text: text,
    channel: channel,
    attachments: attachments
  })
}