import axios from 'axios'

const req = axios.create({
  timeout: 15000
})

req.interceptors.request.use(request => {
  return request
}, error => {
  return Promise.reject(error)
})

req.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
})

export default req